import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import apiClient from "../../api/ApiClients";
import { Heading, Layout } from "../../components";
import constants from "../../utils/constants";
import "./FeedbacksAndRequests.scss";

const Path = constants.routePath;

const FeedbacksAndRequests = () => {
  const [loading, setLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState({
    totalFeedbacks: 0,
    fixingBugs: 0,
    featureRequest: 0,
    generalFeedback: 0,
    others: 0,
  });
  useEffect(() => {
    const fetchFeedbacks = async () => {
      setLoading(true);
      const response = await apiClient.request("feedback/counts", "GET");
      if (response && response.error === false) {
        setFeedbacks({
          totalFeedbacks: response.feedbackCounts.total,
          fixingBugs: response.feedbackCounts["Fixing bugs"],
          featureRequest: response.feedbackCounts["Feature request"],
          generalFeedback: response.feedbackCounts["General feedback"],
          others: response.feedbackCounts["Others"],
        });
      }
      setLoading(false);
    };

    fetchFeedbacks();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Layout>
      <div className="feedbacksAndRequestsPageContainer">
        <Heading title="Feedbacks And Requests" />
        <div className="cardReportWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Total received </p>
                    </div>
                    <div className="flexItemTwo">
                      <p className="number">{feedbacks.totalFeedbacks}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportCatergoryWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={3}>
                <Link to={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}`} state={{ type: "Fixing bugs" }}>
                  <div className="cardWrap fixing">
                    <div className="cardLinkWrap">
                      <h1 className="heading">Fixing bugs</h1>
                      <p className="number">{feedbacks.fixingBugs}</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={3}>
                <Link
                  to={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}`}
                  state={{ type: "Feature request" }}>
                  <div className="cardWrap feature">
                    <div className="cardLinkWrap">
                      <h1 className="heading">Feature request</h1>
                      <p className="number">{feedbacks.featureRequest}</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={3}>
                <Link
                  to={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}`}
                  state={{ type: "General feedback" }}>
                  <div className="cardWrap general">
                    <div className="cardLinkWrap">
                      <h1 className="heading">General feedback</h1>
                      <p className="number">{feedbacks.generalFeedback}</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={3}>
                <Link to={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}`} state={{ type: "Others" }}>
                  <div className="cardWrap other">
                    <div className="cardLinkWrap">
                      <h1 className="heading">Others</h1>
                      <p className="number">{feedbacks.others}</p>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default FeedbacksAndRequests;
