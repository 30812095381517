import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import apiClient from "../../../api/ApiClients";
import Images from "../../../assets/img";
import { Heading, Layout, UserManagementTable } from "../../../components";
import "./Users.scss";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = _.debounce((term) => {
    setSearchTerm(term);
  }, 500);

  const handleToggleDeleted = () => {
    setShowDeleted(!showDeleted);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const response = await apiClient.request(
        `user/all?page=${currentPage}&search=${searchTerm}&deleted=${showDeleted}`,
        "GET"
      );
      if (response && response.user) {
        setUsers(response.user);
        setTotalPages(response.totalPages || 1);
      }
      setLoading(false);
    };

    fetchUsers();
  }, [currentPage, searchTerm, showDeleted]);

  return (
    <Layout>
      <div className="usersPageContainer">
        <Heading title="User management" />

        <div className="dataTableWrapper">
          <Container fluid>
            <Row>
              <Col>
                <div className="cardWrap">
                  <Row className="align-items-center justify-content-between">
                    <Col md="auto">
                      <div className="toggleDeletedWrapper">
                        <label>
                          <input type="checkbox" checked={showDeleted} onChange={handleToggleDeleted} />
                          Show Deleted Users
                        </label>
                      </div>
                    </Col>
                    <Col md="auto" className="ml-3">
                      <div className="searchInputWrapper">
                        <div className="searchWrap">
                          <div className="iconWrap">
                            <Images.SearchIcon />
                          </div>
                          <div className="inputWrap">
                            <input
                              type="text"
                              placeholder="Search users"
                              className="inputControl"
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {loading ? <p>Loading...</p> : <UserManagementTable users={users} />}
                </div>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
              <Pagination>
                {[...Array(totalPages).keys()].map((page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currentPage}
                    onClick={() => handlePageChange(page + 1)}>
                    {page + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Users;
