import React from "react";
import { useLocation } from "react-router-dom";
import { Header, Sidebar } from "../../components";
import constants from "../../utils/constants";
import "./Layout.scss";

const Path = constants.routePath;

const Layout = ({ children }) => {
  const pathname = useLocation().pathname;

  return (
    <div className="layoutContainer">
      <div className="layoutFlexWrap">
        <div className="layoutFlexItemOne">
          <Sidebar />
        </div>
        <div className="layoutFlexItemTwo">
          {pathname !== `/${Path.PROFILE}` && <Header />}
          <div className="mainContentContainer">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
