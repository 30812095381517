import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Heading, Layout } from "../../../components";
import Images from "../../../assets/img";
import constants from "../../../utils/constants";

const Path = constants.routePath;

const ChatsWithMemebers = () => {
  const navigate = useNavigate();

  const handleViewChat = () => {
    const userId = "1";
    const chatId = "3";
    navigate(`/${Path.CHATS}/${userId}/${chatId}`);
  };

  return (
    <Layout>
      <div className="userChatsPageContainer">
        <Heading title="Chats" />
        <div className="cardReportWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Chats with</p>
                    </div>
                    <div className="flexItemTwo">
                      <p className="number">45 members</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="headingWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h1 className="heading">Chats</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportedCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">User</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="greyBtn">
                        View profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportedCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">Chats With</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        {/* <div className="accountStatusLabel offline">Offline</div> */}
                        <div className="accountStatusLabel online">Online</div>
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="greenBtn" onClick={() => handleViewChat()}>
                        View chat
                      </Button>
                      <Button variant="link" className="greyBtn">
                        View profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="greenBtn" onClick={() => handleViewChat()}>
                        View chat
                      </Button>
                      <Button variant="link" className="greyBtn">
                        View profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        {/* <div className="accountStatusLabel offline">Offline</div> */}
                        <div className="accountStatusLabel online">Online</div>
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="greenBtn" onClick={() => handleViewChat()}>
                        View chat
                      </Button>
                      <Button variant="link" className="greyBtn">
                        View profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default ChatsWithMemebers;
