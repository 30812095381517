import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import apiClient from "../../../api/ApiClients";
import "./SignIn.scss";

const validationSchema = yup.object({
  email: yup.string().email("enter a valid email").required(),
  password: yup.string().min(8, "password should be of minimum 8 characters length").required(),
});

const SignIn = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      signIn(values);
    },
  });

  const signIn = async (values) => {
    if (!values.email || !values.password) {
      toast.error("Email and password are required.");
      return;
    }
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const data = await apiClient.request(
        "admin/login",
        "POST",
        {
          email: values.email,
          password: values.password,
        },
        false,
        {},
        true
      );

      if (data && data.token) {
        const token = data.token;
        Cookies.set("token", token);
        navigate("/");
        toast.success("Login successful");
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="signInPageContainer">
        <div className="">
          <Container>
            <Row>
              <Col>
                <Form onSubmit={formik.handleSubmit}>
                  <div className="singInFormWrapper">
                    <div className="headerWrap">
                      <h3 className="heading">Welcome Back</h3>
                      <p className="subHeading">Enter your email and password to sign in</p>
                    </div>
                    <div className="">
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Your email address"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          isInvalid={formik.touched.email && Boolean(formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Your password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          isInvalid={formik.touched.password && Boolean(formik.errors.password)}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Check type="switch" id="custom-switch" label="Remember Me" />
                      <Button disabled={isSubmitting} variant="primary" type="submit" className="submit-btn teal-btn">
                        SIGN IN
                      </Button>
                      {/* <div className="signUpLinkWrap">
                    <p className="signUpTxt">Don't have an account? <Link to="/" > Sign Up </Link></p>
                  </div> */}
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignIn;
