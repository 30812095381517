import React, { Fragment } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import "./Breadcrumbs.scss";

const Breadcrumbs = () => {
  const pathname = useLocation().pathname;
  const params = useParams();
  let breadcrumbs = useBreadcrumbs();

  let pageTitle = [
    { key: "/", name: "Dashboard" },
    { key: "/user-management", name: "User Management" },
    { key: "/user-management/users", name: "All Users" },
    { key: "/user-management/users/", name: "Individual", id: "userId" },
    { key: "/reports", name: "Reports" },
    { key: "/reports/pending-reports", name: "Pending Reports" },
    { key: "/reports/pending-reports/", name: "View Pending Report", id: "pendingReportId" },
    { key: "/reports/resolved-reports", name: "Resolved Reports" },
    { key: "/reports/resolved-reports/", name: "View Resolved Report", id: "resolvedReportId" },
    { key: "/profile", name: "Profile" },
    { key: "/chats", name: "Chats" },
    { key: "/feedbacks-and-requests", name: "Feedbacks And Requests" },
    { key: "/feedbacks-and-requests/fixing-bugs", name: "Fixing Bugs" },
    { key: "/feedbacks-and-requests/feature-request", name: "Feature Request" },
    { key: "/feedbacks-and-requests/feature-request/", name: "Detailed Feature Request", id: "featureRequestId" },
  ];

  if (pathname.includes("chats") && params.userId) {
    pageTitle.push({ key: `/chats/${params.userId}`, name: "Chats with members" });
  }
  if (pathname.includes("chats") && params.chatId) {
    pageTitle.push({ key: `/chats/${params.userId}/${params.chatId}`, name: "View detailed chats" });
  }

  pageTitle.forEach((a) => {
    breadcrumbs.map((b) => {
      if (a.key === b.key) {
        b.match["pageTitle"] = a.name;
      }
      if (b.key.includes(a.key) && a.id === "userId") {
        b.match["pageTitle"] = a.name;
      }
      if (b.key.includes(a.key) && a.id === "pendingReportId") {
        b.match["pageTitle"] = a.name;
      }
      if (b.key.includes(a.key) && a.id === "resolvedReportId") {
        b.match["pageTitle"] = a.name;
      }
      if (b.key.includes(a.key) && a.id === "featureRequestId") {
        b.match["pageTitle"] = a.name;
      }
      return b;
    });
  });
  breadcrumbs = breadcrumbs.filter((a) => a.key !== "/");

  return (
    <Breadcrumb className="breadcrumbsComponentContainer">
      {breadcrumbs.length ? (
        breadcrumbs.map(({ match, key }) => {
          return (
            <Fragment key={match.pageTitle}>
              <Breadcrumb.Item
                className={`${pathname === key ? "active" : ""} navLinkItem`}
                linkAs={Link}
                linkProps={{ to: match.pathname || "" }}>
                {match.pageTitle}
              </Breadcrumb.Item>
            </Fragment>
          );
        })
      ) : (
        <Breadcrumb.Item className="active navLinkItem" linkAs={Link} linkProps={{ to: "/" }}>
          Dashboard
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
