import Cookies from "js-cookie";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Chats,
  ChatsWithMemebers,
  Dashboard,
  DetailedFeatureRequest,
  FeatureRequest,
  FeedbacksAndRequests,
  PendingReports,
  Profile,
  Reports,
  ResolvedReports,
  SignIn,
  UserDetails,
  Users,
  ViewChats,
  ViewPendingReport,
  ViewResolvedReport,
} from "../pages";
import constants from "../utils/constants";

const Path = constants.routePath;

const PrivateRoute = ({ children }) => {
  const isAuthenticated = Cookies.get("token");
  return isAuthenticated ? children : <Navigate to={`/${Path.SIGNIN}`} replace />;
};

const PublicRoute = ({ children }) => {
  const isAuthenticated = Cookies.get("token");
  return isAuthenticated ? <Navigate to="/" replace /> : children;
};

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path={`/${Path.SIGNIN}`}
          element={
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path={`/${Path.USER_MANAGEMENT}`}
          element={
            <PrivateRoute>
              <Navigate to={`/${Path.USER_MANAGEMENT}/${Path.USERS}`} replace />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.USER_MANAGEMENT}/${Path.USERS}`}
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.USER_MANAGEMENT}/${Path.USERS}/:userId`}
          element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={`/${Path.REPORTS}`}
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.REPORTS}/${Path.PENDING_REPORTS}`}
          element={
            <PrivateRoute>
              <PendingReports />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.REPORTS}/${Path.PENDING_REPORTS}/:pendingReportId`}
          element={
            <PrivateRoute>
              <ViewPendingReport />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.REPORTS}/${Path.RESOLVED_REPORTS}`}
          element={
            <PrivateRoute>
              <ResolvedReports />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.REPORTS}/${Path.RESOLVED_REPORTS}/:resolvedReportId`}
          element={
            <PrivateRoute>
              <ViewResolvedReport />
            </PrivateRoute>
          }
        />

        <Route
          path={`/${Path.PROFILE}`}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path={`/${Path.CHATS}`}
          element={
            <PrivateRoute>
              <Chats />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.CHATS}/:userId`}
          element={
            <PrivateRoute>
              <ChatsWithMemebers />
            </PrivateRoute>
          }
        />
        <Route
          path={`/${Path.CHATS}/:userId/:chatId`}
          element={
            <PrivateRoute>
              <ViewChats />
            </PrivateRoute>
          }
        />
        <Route path={`/${Path.FEEDBACKS_AND_REQUESTS}`} element={<FeedbacksAndRequests />} />
        <Route path={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}`} element={<FeatureRequest />} />
        <Route
          path={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}/:requestId`}
          element={<DetailedFeatureRequest />}
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
