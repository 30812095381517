import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Heading.scss";

const Heading = ({ title }) => {
  return (
    <div className="headingComponentContainer">
      <Container fluid>
        <Row>
          <Col>
            <h1 className="heading">{title}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Heading;
