export const SIGNIN = "signin";
export const DASHBOARD = "dashboard";
export const USER_MANAGEMENT = "user-management";
export const USERS = "users";
export const REPORTS = "reports";
export const PENDING_REPORTS = "pending-reports";
export const VIEW_PENDING_REPORT = "view-pending-report";
export const RESOLVED_REPORTS = "resolved-reports";
export const VIEW_RESOLVED_REPORT = "view-resolved-report";
export const PROFILE = "profile";
export const CHATS = "chats";
export const FEEDBACKS_AND_REQUESTS = "feedbacks-and-requests";
export const FEEDBACKS_BY_TYPE = "feedbacks";
