import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Heading, Layout } from "../../components";
import Images from "../../assets/img";
import constants from "../../utils/constants";
import "./Dashboard.scss";

const Path = constants.routePath;

const Dashboard = () => {
  return (
    <Layout>
      <div className="dashboardPageContainer">
        <Heading title="Dashboard" />
        <div className="cardAmountWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Today's Money</p>
                      <p>
                        <span className="amount">$53,000</span>
                        <span className="percentage positive">+55%</span>
                        {/* <span className="percentage negative">-14%</span> */}
                      </p>
                    </div>
                    <div className="flexItemTwo">
                      <div className="iconWrap">
                        <Images.WalletIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Today's Users</p>
                      <p>
                        <span className="amount">2,300</span>
                        <span className="percentage positive">+5%</span>
                        {/* <span className="percentage negative">-14%</span> */}
                      </p>
                    </div>
                    <div className="flexItemTwo">
                      <div className="iconWrap">
                        <Images.GlobeIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">New Users</p>
                      <p>
                        <span className="amount">+3,052</span>
                        {/* <span className="percentage positive">+55%</span> */}
                        <span className="percentage negative">-14%</span>
                      </p>
                    </div>
                    <div className="flexItemTwo">
                      <div className="iconWrap">
                        <Images.DocumentIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Total Sales</p>
                      <p>
                        <span className="amount">$173,000</span>
                        <span className="percentage positive">+8%</span>
                        {/* <span className="percentage negative">-14%</span> */}
                      </p>
                    </div>
                    <div className="flexItemTwo">
                      <div className="iconWrap">
                        <Images.CartIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Total Users</p>
                      <p>
                        <span className="amount">56,785</span>
                        <span className="percentage positive">+65%</span>
                        {/* <span className="percentage negative">-14%</span> */}
                      </p>
                    </div>
                    <div className="flexItemTwo">
                      <div className="iconWrap">
                        <Images.GlobeIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Active Users</p>
                      <p>
                        <span className="amount">50,297</span>
                        {/* <span className="percentage positive">+8%</span> */}
                        <span className="percentage negative">-9%</span>
                      </p>
                    </div>
                    <div className="flexItemTwo">
                      <div className="iconWrap">
                        <Images.UserIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
