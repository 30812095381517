import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../../components";

const ViewResolvedReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [report] = useState(location.state?.report || null);

  const onDoneClick = () => {
    navigate("/reports/resolved-reports");
  };
  if (!report) {
    return (
      <Layout>
        <div className="usersDetailsPageContainer">
          <div className="cardInfoWrapper">
            <Container fluid>
              <h3>User data not found</h3>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="viewResolvedReportPageContainer">
        <div className="headingWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h1 className="heading">Resolved Reports</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportedCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">Reported By</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={report.reporter.profileImage} className="portrait" />
                      <div className="infoBox">
                        <p className="name">{report.reporter.name}</p>
                        <p className="userId">
                          <span>Email:</span>
                          <span className="id">{report.reporter.email}</span>
                        </p>
                      </div>
                      {/* <div className="statusWrapper">
                      <div className="accountStatusLabel offline">Offline</div>
                    </div> */}
                    </div>
                    <div className="flexItemTwo">
                      <Link
                        to={`/user-management/users/${report.reporter.name}`}
                        state={{ userId: report.reporter._id }}
                        variant="link"
                        className="greyBtn">
                        View profile
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportedCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">Reported Against</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={report.otherUser.profileImage} className="portrait" />
                      <div className="infoBox">
                        <p className="name">{report.otherUser.name}</p>
                        <p className="userId">
                          <span>Email:</span>
                          <span className="id">{report.otherUser.email}</span>
                        </p>
                      </div>
                      {/* <div className="statusWrapper">
                      <div className="accountStatusLabel online">Online</div>
                    </div> */}
                    </div>
                    <div className="flexItemTwo">
                      <Link
                        to={`/user-management/users/${report.otherUser.name}`}
                        state={{ userId: report.otherUser._id }}
                        variant="link"
                        className="greyBtn">
                        View profile
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportDetailsCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">Report Details</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="infoBox">
                    <div className="flexItem">
                      <span className="label">Category</span>
                    </div>
                    <div className="flexItem">
                      <span className="info">{report.reasons && report.reasons[0]}</span>
                    </div>
                  </div>
                  <div className="infoBox">
                    <div className="flexItem">
                      <span className="label">Reported on</span>
                    </div>
                    <div className="flexItem">
                      <span className="info">{report.reportedAt}</span>
                    </div>
                  </div>
                  <div className="infoBox">
                    <div className="flexItem">
                      <span className="label">Comments</span>
                    </div>
                    <div className="flexItem">
                      <span className="info">{report.reasons && report.reasons[1]}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="actionBtnWrap">
                  <Button variant="link" className="greyBtn" onClick={onDoneClick}>
                    Done
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default ViewResolvedReport;
