import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const ResolvedReportsTable = ({ reports }) => {
  const columns = [
    {
      name: "Reported By",
      sortable: true,
      width: "300px",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="userInfoWrapper">
          <div className="flexWrap">
            <div className="flexItemOne">
              <img alt="portrait" src={row.portrait} className="portrait" />
            </div>
            <div className="flexItemTwo">
              <p className="name">{row.name}</p>
              <p className="email">{row.email}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Action Status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => (
        <div className="verificationStatusWrapper">
          <div className="verificationStatusLabel verified">{row.status}</div>
        </div>
      ),
    },
    {
      name: "Reported User",
      sortable: true,
      width: "300px",
      selector: (row) => row.reportedUser_name,
      cell: (row) => (
        <div className="userInfoWrapper">
          <div className="flexWrap">
            <div className="flexItemOne">
              <img alt="portrait" src={row.reportedUser_portrait} className="portrait" />
            </div>
            <div className="flexItemTwo">
              <p className="name">{row.reportedUser_name}</p>
              <p className="email">{row.reportedUser_email}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Reported At",
      sortable: true,
      selector: (row) => row.reportedAt,
      cell: (row) => (
        <div>
          <div className="locationLabel">{row.reportedAt}</div>
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <div className="actionButtonWrapper">
            <Link
              to={`/reports/resolved-reports/${row.name}`}
              variant="link"
              className="editBtn"
              state={{ report: row }}>
              View
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const data = reports.map((report) => ({
    ...report,
    id: report._id,
    name: report.reporter.name,
    email: report.reporter.email,
    portrait: report.reporter.profileImage,
    status: "Resolved",
    reportedUser_name: report.otherUser.name,
    reportedUser_email: report.otherUser.email,
    reportedUser_portrait: report.otherUser.profileImage,
    reportedAt: new Date(report.createdAt).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
  }));

  return <DataTable columns={columns} data={data} />;
};

export default ResolvedReportsTable;
