import photo1 from "../../assets/img/01-photo.png";
import photo2 from "../../assets/img/02-photo.png";
import photo3 from "../../assets/img/03-photo.png";
import photo4 from "../../assets/img/04-photo.png";
import photo5 from "../../assets/img/05-photo.png";
import photo6 from "../../assets/img/06-photo.png";
import photo7 from "../../assets/img/07-photo.png";
import { ReactComponent as BoxIcon } from "../../assets/img/boxIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/cancelIcon.svg";
import { ReactComponent as CartIcon } from "../../assets/img/cartIcon.svg";
import { ReactComponent as ChatIcon } from "../../assets/img/chatIcon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/img/checkmarkIcon.svg";
import { ReactComponent as ClockIcon } from "../../assets/img/clockIcon.svg";
import { ReactComponent as DangerIcon } from "../../assets/img/dangerIcon.svg";
import { ReactComponent as DocumentIcon } from "../../assets/img/documentIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/img/editIcon.svg";
import { ReactComponent as EditPencilIcon } from "../../assets/img/editPencilIcon.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/img/exclamationIcon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/img/facebookIcon.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/img/feedback.svg";
import { ReactComponent as GlobeIcon } from "../../assets/img/globeIcon.svg";
import { ReactComponent as HomeIcon } from "../../assets/img/homeIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/img/instagramIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/img/logoutIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/img/profileIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/searchIcon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/twitterIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/img/userIcon.svg";
import { ReactComponent as WalletIcon } from "../../assets/img/walletIcon.svg";
import UserPortrait from "./userPortrait.png";
import userPortrait2 from "./userPortrait2.png";
import WaveBackground from "./waveBackground.png";

const Images = {
  BoxIcon,
  CancelIcon,
  CartIcon,
  ChatIcon,
  CheckmarkIcon,
  ClockIcon,
  DangerIcon,
  DocumentIcon,
  EditIcon,
  EditPencilIcon,
  ExclamationIcon,
  FacebookIcon,
  GlobeIcon,
  HomeIcon,
  InstagramIcon,
  LogoutIcon,
  ProfileIcon,
  SearchIcon,
  TwitterIcon,
  UserIcon,
  WalletIcon,
  WaveBackground,
  UserPortrait,
  userPortrait2,
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  FeedbackIcon,
};

export default Images;
