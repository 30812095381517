import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TotalReportsReceived = ({ number }) => {
  return (
    <div className="cardReportWrapper">
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={4}>
            <div className="cardWrap">
              <div className="flexWrap">
                <div className="flexItemOne">
                  <p className="title">Total reports received </p>
                </div>
                <div className="flexItemTwo">
                  <p className="number">{number}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TotalReportsReceived;
