import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import Images from "../../assets/img";
import { Layout } from "../../components";
import constants from "../../utils/constants";
import "./Profile.scss";

const Path = constants.routePath;

const Profile = () => {
  const [pushNotifications, setPushNotifications] = useState(true);

  return (
    <Layout>
      <div className="profilePageContainer">
        <div className="profileCardWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="bgLayerWrap">
                  <Image src={Images.WaveBackground} alt="portrait" className="bglayerImg" fluid />
                  <div className="textLayerWrap">
                    <p className="title">Profile</p>
                  </div>
                </div>
                <div className="profileEditWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <div className="userImgBox">
                        <Image alt="portrait" src={Images.userPortrait2} className="portrait" fluid />
                        <Button variant="link" className="editBtn">
                          <Images.EditIcon />
                        </Button>
                      </div>
                      <div className="infoBox">
                        <p className="name">Admin</p>
                        <p className="userId">admin@muslym.app</p>
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="whiteBtn">
                        <Images.BoxIcon />
                        Overview
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="settingWrap">
                  <Container fluid>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                        <div className="notificationWrap">
                          <div className="cardWrap">
                            <h3 className="heading">Notifications</h3>
                            <Form>
                              <div className="">
                                <p className="label">Push Notifications</p>
                                <Form.Check
                                  type="switch"
                                  id="messageSwitch"
                                  label="Message me when anything happens"
                                  defaultChecked={pushNotifications}
                                />
                              </div>
                              <div className="">
                                <p className="label">In App Notifications</p>
                                <Form.Check type="switch" id="appNotificationSwitch" label="In app notifications" />
                              </div>
                            </Form>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                        <div className="profileInfoWrap">
                          <div className="cardWrap">
                            <h3 className="heading">Profile Information</h3>
                            <div className="infoBox">
                              <p className="infoTxt">
                                <span className="label">Full Name: </span>
                                <span className="txt">Administator</span>
                              </p>
                            </div>
                            <div className="infoBox">
                              <p className="infoTxt">
                                <span className="label">Mobile: </span>
                                <span className="txt">(+91) 123 1234 123</span>
                              </p>
                            </div>
                            <div className="infoBox">
                              <p className="infoTxt">
                                <span className="label">Email: </span>
                                <span className="txt">admin@muslym.app</span>
                              </p>
                            </div>
                            <div className="infoBox">
                              <p className="infoTxt">
                                <span className="label">Location: </span>
                                <span className="txt">Mumbai, India</span>
                              </p>
                            </div>
                            <div className="infoBox">
                              <p className="infoTxt">
                                <span className="label">Social Media: </span>
                                <span className="txt socialIcon">
                                  <Button variant="link">
                                    <Images.FacebookIcon />
                                  </Button>
                                  <Button variant="link">
                                    <Images.TwitterIcon />
                                  </Button>
                                  <Button variant="link">
                                    <Images.InstagramIcon />
                                  </Button>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
