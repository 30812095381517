import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import apiClient from "../../../api/ApiClients";
import Images from "../../../assets/img";
import { Heading, Layout } from "../../../components";
import constants from "../../../utils/constants";

const Path = constants.routePath;

const FeatureRequest = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    const validTypes = ["Fixing bugs", "Feature request", "General feedback", "Others"];
    if (location.state?.type && validTypes.includes(location.state?.type)) {
      const fetchFeedback = async () => {
        setLoading(true);
        const response = await apiClient.request(`feedback/${location.state?.type}`, "GET");
        if (response && response.feedbacks && response.error === false) {
          setFeedback(response.feedbacks);
        }
        setLoading(false);
      };
      fetchFeedback();
    }
  }, [location.state?.type]);

  if (loading) {
    return (
      <Layout>
        <div className="featureRequestPageContainer">
          <div className="chatListWrapper">
            <Container fluid>
              <h3>Loading...</h3>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="featureRequestPageContainer">
        <Heading title="Feature Request" />
        <div className="chatListWrapper">
          <Container fluid>
            <Row>
              {feedback.map((item, index) => (
                <Col key={index} xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                  <div className="cardWrap">
                    <div className="flexWrap">
                      <div className="flexItemOne">
                        <img
                          alt="portrait"
                          src={item.userId.profileImage || Images.UserPortrait}
                          className="portrait"
                        />
                        <div className="infoBox">
                          <p className="name">{item.userId.name}</p>
                          <p className="userId">
                            <span>Email:</span>
                            <span className="id">{item.userId.email}</span>
                          </p>
                        </div>
                        <div className="statusWrapper">
                          <div className="accountStatusLabel online">Online</div>
                        </div>
                      </div>
                      <div className="flexItemTwo">
                        <div className="actionButtonWrapper">
                          <Link
                            to={`/${Path.FEEDBACKS_AND_REQUESTS}/${Path.FEEDBACKS_BY_TYPE}/${item.userId.name}`}
                            state={{ feedback: item }}
                            className="viewBtn">
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default FeatureRequest;
