import React, { useEffect, useState } from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import apiClient from "../../../api/ApiClients";
import { Heading, Layout, PendingReportsTable } from "../../../components";

const PendingReports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      const response = await apiClient.request(`userInteractions/reports-by-status?page=${currentPage}`, "POST", {
        resolved: false,
      });
      if (response && response.error === false && response.reports) {
        setReports(response.reports);
        setTotalPages(response.totalPages || 1);
      }
      setLoading(false);
    };

    fetchReports();
  }, [currentPage]);

  return (
    <Layout>
      <div className="pendingReportsPageContainer">
        <Heading title="Reports" />
        <div className="dataTableWrapper">
          <Container fluid>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <Row>
                  <Col>
                    <div className="cardWrap">
                      <h1 className="heading">Pending Reports</h1>
                      <PendingReportsTable reports={reports} />
                    </div>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                  <Pagination>
                    {[...Array(totalPages).keys()].map((page) => (
                      <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}>
                        {page + 1}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                </div>
              </>
            )}
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default PendingReports;
