import { React, useEffect, useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../../api/ApiClients";
import Images from "../../../assets/img";
import { Heading, Layout } from "../../../components";
import "./UserDetails.scss";

const UserDetails = () => {
  const location = useLocation();

  const [user, setUser] = useState(location.state?.user || null);
  const [loading, setLoading] = useState(false);

  const [removePhotoModalShow, setRemovePhotoModalShow] = useState(false);
  const [rejectSelfieModalShow, setRejectSelfieModalShow] = useState(false);
  const [accountSuspendModalShow, setAccountSuspendModalShow] = useState(false);

  const [loadingSuspend, setLoadingSuspend] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  useEffect(() => {
    if (location.state?.userId) {
      const fetchUser = async () => {
        setLoading(true);
        const response = await apiClient.request("user/get-user", "POST", { userId: location.state.userId });
        if (response && response.user) {
          setUser(response.user);
        }
        setLoading(false);
      };
      fetchUser();
    }
  }, [location.state?.userId]);

  const handleAccept = async () => {
    if (!user._id) {
      toast.error("User data not found");
      return;
    }
    setLoadingAccept(true);
    const response = await apiClient.request("user/verify", "POST", {
      userId: user._id,
      verified: true,
    });
    if (response && response.success) {
      setUser((prevUser) => ({ ...prevUser, isVerifiedAccount: true, accountStatus: "accepted" }));
      toast.success("Account accepted successfully");
    }
    setLoadingAccept(false);
  };

  const handleRejectSelfie = async () => {
    if (!user._id) {
      toast.error("User data not found");
      return;
    }
    setLoadingReject(true);
    const response = await apiClient.request("user/verify", "POST", {
      userId: user._id,
      verified: false,
    });
    if (response && response.success) {
      setUser((prevUser) => ({ ...prevUser, accountStatus: "rejected" }));
      setRejectSelfieModalShow(false);
      toast.success("Account rejected successfully");
    }
    setLoadingReject(false);
  };
  const handleRejectSelfieClose = () => setRejectSelfieModalShow(false);
  const handleRejectSelfieOpen = () => setRejectSelfieModalShow(true);

  const handleAccountSuspend = async () => {
    if (!user._id) {
      toast.error("User data not found");
      return;
    }
    setLoadingSuspend(true);
    const response = await apiClient.request("user/suspend", "POST", {
      userId: user._id,
      suspended: user?.suspended === null ? true : !user.suspended,
    });
    if (response && response.success) {
      setUser((prevUser) => ({
        ...prevUser,
        suspended: !prevUser.suspended,
        accountStatus: !prevUser.suspended ? "suspended" : "accepted",
      }));
      setAccountSuspendModalShow(false);
      if (user.suspended) {
        toast.success("Account suspended successfully");
      } else {
        toast.success("Account suspension removed successfully");
      }
    }
    setLoadingSuspend(false);
  };
  const handleAccountSuspendClose = () => setAccountSuspendModalShow(false);
  const handleAccountSuspendOpen = () => setAccountSuspendModalShow(true);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleRemovePhotoOpen = (index) => {
    setSelectedImageIndex(index);
    setRemovePhotoModalShow(true);
  };

  const handleRemovePhotoClose = () => {
    setRemovePhotoModalShow(false);
  };

  const handleRemovePhotoConfirm = async () => {
    if (!user._id) {
      toast.error("User data not found");
      return;
    }
    if (user.images.length <= 2) {
      toast.error("Cannot remove image. Minimum 2 images required.");
    } else {
      const response = await apiClient.request("user/delete-image", "POST", {
        userId: user._id,
        image: user.images[selectedImageIndex],
      });
      if (response && response.success) {
        const newImages = user.images.filter((_, i) => i !== selectedImageIndex);
        setUser((prevUser) => ({ ...prevUser, images: newImages }));
        toast.success("Image removed successfully");
      }
    }
    setRemovePhotoModalShow(false);
  };

  if (loading) {
    return (
      <Layout>
        <div className="usersDetailsPageContainer">
          <div className="cardInfoWrapper">
            <Container fluid>
              <h3>Loading...</h3>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }

  if (!user) {
    return (
      <Layout>
        <div className="usersDetailsPageContainer">
          <div className="cardInfoWrapper">
            <Container fluid>
              <h3>User data not found</h3>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <>
      <Layout>
        <div className="usersDetailsPageContainer">
          <Heading title="User management" />
          <div className="cardInfoWrapper">
            <Container fluid>
              <Row>
                <Col>
                  <div className="cardWrap">
                    <div className="flexWrap">
                      <div className="flexItemOne">
                        <img alt="portrait" src={user?.profileImage} className="portrait" />
                        <div className="infoBox">
                          <p className="name">{user?.name}</p>
                          {/* <p className="userId">
                        <span>User ID:</span>
                        <span className="id">ABC12345</span>
                      </p> */}
                        </div>
                        <div className="statusWrapper">
                          <div className={`accountStatusLabel ${user?.accountStatus || "pending"}`}>
                            {user?.accountStatus
                              ? user.accountStatus.charAt(0).toUpperCase() + user.accountStatus.slice(1)
                              : "Pending"}
                          </div>
                        </div>
                      </div>
                      <div className="flexItemTwo">
                        <p className="timeBox">
                          <Images.ClockIcon />
                          <span className="time">6h ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="editWrapper">
            <Container fluid>
              <Row>
                <Col>
                  <div className="selfieVerificationWrapper">
                    <h1 className="headingWrap">
                      Selfie verification
                      {user?.isVerifiedAccount === true ? (
                        <div className="accountStatusLabel verified">Verified</div>
                      ) : (
                        <div className="accountStatusLabel pending">Pending</div>
                      )}
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={9} xxl={9}>
                  <div className="selfiePhotosWrapper">
                    <div className="cardWrap">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                          <h2 className="subHeading">Selfie Taken</h2>
                          <div className="selfieImgWrap">
                            <div className="imgWrap">
                              <a href={user?.selfieUrl} target="_blank" rel="noopener noreferrer">
                                <Image src={user?.selfieUrl} fluid style={{ cursor: "pointer" }} />
                              </a>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8}>
                          <h2 className="subHeading">Uploaded Photos</h2>
                          <div className="uploadedImgWrap">
                            <Row>
                              {user?.images.map((image, index) => (
                                <Col key={index} xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                                  <div className="imgWrap">
                                    <Button
                                      className="removeBtn"
                                      variant="link"
                                      onClick={() => handleRemovePhotoOpen(index)}>
                                      <Images.CancelIcon />
                                    </Button>
                                    <a href={image} target="_blank" rel="noopener noreferrer">
                                      <Image src={image} fluid style={{ cursor: "pointer" }} />
                                    </a>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={8} md={7} lg={7} xl={7} xxl={7}>
                          <div className="uploadInfoWrap">
                            <p className="infoTxt">
                              <Images.ExclamationIcon />
                              If the uploaded photos are inappropriate/nude/extremely exposed/immodest then click the
                              cross mark to cancel that particular photo.
                            </p>
                          </div>
                        </Col>
                        <Col xs={12} sm={4} md={5} lg={5} xl={5} xxl={5} className="text-end">
                          <Button
                            disabled={loadingAccept}
                            variant="link"
                            className="editBtn accept"
                            onClick={() => handleAccept()}>
                            Accept
                          </Button>
                          <Button
                            disabled={loadingReject}
                            variant="link"
                            className="editBtn reject"
                            onClick={() => handleRejectSelfieOpen()}>
                            Reject
                          </Button>
                          {/* <Button variant="link" className="editBtn verified" onClick={() => handleVerified()}>
                        <Images.CheckmarkIcon />
                        Verified
                      </Button> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                  <div className="activityWrapper">
                    <div className="cardWrap">
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Last login</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">3:45 PM IST</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Used for</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">1h 45m</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Daily average usage</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">12 mins</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Number of daily average logins or comebacks</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">1</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Weekly logins or comebacks (Day wise)</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">1</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Requests sent/received</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">122/56</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Requests accepted/rejected by this user</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">30/34</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Number of people who accepted/rejected</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">56/96</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Total profiles visited by this users</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">845</span>
                        </div>
                      </div>
                      <div className="infoBox">
                        <div className="flexItem">
                          <span className="label">Total matches</span>
                        </div>
                        <div className="flexItem">
                          <span className="info">41</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={9} xxl={9}>
                  <div className="basicDetailsWrapper">
                    <h1 className="heading">Basic details</h1>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={4} className="fluidHeight">
                        <div className="userDetailsWrapper">
                          <div className="cardWrap">
                            <div className="infoBox">
                              <div className="flexItem">
                                <span className="label">Name</span>
                                <span className="info">{user?.name}</span>
                              </div>
                              <div className="flexItem">
                                <Button variant="link" className="editBtn">
                                  <Images.EditPencilIcon />
                                </Button>
                              </div>
                            </div>
                            <div className="infoBox">
                              <div className="flexItem">
                                <span className="label">Date of birth</span>
                                <span className="info">{user?.dob}</span>
                              </div>
                              <div className="flexItem">
                                <Button variant="link" className="editBtn">
                                  <Images.EditPencilIcon />
                                </Button>
                              </div>
                            </div>
                            <div className="infoBox">
                              <div className="flexItem">
                                <span className="label">Gender</span>
                                <span className="info">{user?.gender}</span>
                              </div>
                              <div className="flexItem">
                                <Button variant="link" className="editBtn">
                                  <Images.EditPencilIcon />
                                </Button>
                              </div>
                            </div>
                            <div className="infoBox">
                              <div className="flexItem">
                                <span className="label">Location</span>
                                <span className="info">{user?.locationName}</span>
                              </div>
                              <div className="flexItem">
                                <Button variant="link" className="editBtn">
                                  <Images.EditPencilIcon />
                                </Button>
                              </div>
                            </div>
                            <div className="infoBox">
                              <div className="flexItem">
                                <span className="label">Email ID</span>
                                <span className="info">{user?.email}</span>
                              </div>
                              <div className="flexItem">
                                <Button variant="link" className="editBtn">
                                  <Images.EditPencilIcon />
                                </Button>
                              </div>
                            </div>
                            <div className="infoBox">
                              <div className="flexItem">
                                <span className="label">Mobile</span>
                                <span className="info">{user?.phoneNumber}</span>
                              </div>
                              <div className="flexItem">
                                <Button variant="link" className="editBtn">
                                  <Images.EditPencilIcon />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={4} className="fluidHeight">
                        <div className="userBioPassWrapper">
                          <div className="cardWrap">
                            <h6 className="title">Bio</h6>
                            <p className="txt">{user?.bio}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={4} className="fluidHeight">
                        <div className="userBioPassWrapper">
                          <div className="cardWrap">
                            <h6 className="title">Password to my heart is</h6>
                            <p className="txt">{user?.passwordToHeart}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                  <div className="reportsWrapper">
                    <h1 className="heading">Reports</h1>
                    <div className="cardWrap">
                      <div className="infoBox">
                        <div className="flexWrap">
                          <div className="flexItem">
                            <span className="label">Reports received against this user</span>
                          </div>
                          <div className="flexItem">
                            <span className="info">6</span>
                          </div>
                        </div>
                        <div className="viewBtnWrap">
                          <Button className="viewBtn" variant="link">
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="cardWrap">
                      <div className="infoBox">
                        <div className="flexWrap">
                          <div className="flexItem">
                            <span className="label">Reports received from this user</span>
                          </div>
                          <div className="flexItem">
                            <span className="info">5</span>
                          </div>
                        </div>
                        <div className="viewBtnWrap">
                          <Button className="viewBtn" variant="link">
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="suspendAccountBtnWrap">
                      {/* use active class for active style */}
                      {/* use inactive class for inactive style */}
                      <Button className="suspendAccountBtn active" variant="link" onClick={handleAccountSuspendOpen}>
                        {user?.suspended ? "Remove Suspension" : "Suspend Account"}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Modal show={removePhotoModalShow} onHide={handleRemovePhotoClose} dialogClassName="modal570w">
            <Modal.Body>
              <div className="cmnModalWrapper deletPhotoModalWrapper">
                <div className="contentWrap">
                  <h6 className="heading">Delete photo</h6>
                  <p className="info">Are you sure you want to permanently delete this photo?</p>
                </div>
                <div className="actionBtnWrap">
                  <Button className="actionBtn tealBtn" variant="link" onClick={handleRemovePhotoConfirm}>
                    Delete
                  </Button>
                  <Button className="actionBtn whiteBtn" variant="link" onClick={handleRemovePhotoClose}>
                    Keep it
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={rejectSelfieModalShow} onHide={handleRejectSelfieClose} dialogClassName="modal570w">
            <Modal.Body>
              <div className="cmnModalWrapper rejectSelfieModalWrapper">
                <div className="contentWrap">
                  <h6 className="heading">Reject selfie verification</h6>
                  <p className="info">Are you sure you want to reject this account from being verified?</p>
                </div>
                <div className="actionBtnWrap">
                  <Button className="actionBtn tealBtn" variant="link" onClick={handleRejectSelfie}>
                    Reject
                  </Button>
                  <Button className="actionBtn whiteBtn" variant="link" onClick={handleRejectSelfieClose}>
                    Review again
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={accountSuspendModalShow} onHide={handleAccountSuspendClose} dialogClassName="modal570w">
            <Modal.Body>
              <div className="cmnModalWrapper rejectSelfieModalWrapper">
                <div className="contentWrap">
                  <h6 className="heading">Account suspension</h6>
                  <p className="info">Are you sure you want to suspend this account?</p>
                </div>
                <div className="actionBtnWrap">
                  <Button
                    disabled={loadingSuspend}
                    className="actionBtn tealBtn"
                    variant="link"
                    onClick={handleAccountSuspend}>
                    {user?.suspended ? "Remove" : "Suspend"}
                  </Button>
                  <Button className="actionBtn whiteBtn" variant="link" onClick={handleAccountSuspendClose}>
                    Review again
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default UserDetails;
