import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../../api/ApiClients";
import Images from "../../../assets/img";
import { Heading, Layout } from "../../../components";

const DetailedFeatureRequest = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(location?.state?.feedback);
  if (!feedback) {
    return (
      <Layout>
        <div className="detailedFeatureRequestPageContainer">Feedback not found</div>
      </Layout>
    );
  }

  const markAsViewed = async () => {
    if (feedback._id && feedback.read === false && !loading) {
      setLoading(true);
      try {
        const response = await apiClient.request("feedback/mark-as-read", "POST", {
          feedbackId: feedback._id,
        });
        if (response && response.error === false) {
          toast.success("Feedback marked as viewed successfully");
          setFeedback({ ...feedback, read: true });
        }
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Layout>
        <div className="detailedFeatureRequestPageContainer">
          <Heading title={feedback.type} />
          <div className="reportedCardInfoWrapper">
            <Container fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <p className="heading">Feedback By</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <div className="cardWrap">
                    <div className="flexWrap">
                      <div className="flexItemOne">
                        <img
                          alt="portrait"
                          src={feedback.userId.profileImage || Images.UserPortrait}
                          className="portrait"
                        />
                        <div className="infoBox">
                          <p className="name">{feedback.userId.name}</p>
                          <p className="userId">
                            <span>Email:</span>
                            <span className="id">{feedback.userId.email}</span>
                          </p>
                        </div>
                        <div className="statusWrapper">
                          <div className="accountStatusLabel offline">Offline</div>
                          {/* <div className="accountStatusLabel online">Online</div> */}
                        </div>
                      </div>
                      <div className="flexItemTwo">
                        <Link
                          to={`/user-management/users/${feedback.userId.name}`}
                          state={{ userId: feedback.userId._id }}
                          className="greyBtn">
                          View profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="reportDetailsCardInfoWrapper">
            <Container fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <p className="heading">Details</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <div className="cardWrap">
                    <div className="infoBox">
                      <div className="flexItem">
                        <span className="label">Category</span>
                      </div>
                      <div className="flexItem">
                        <span className="info">{feedback.type}</span>
                      </div>
                    </div>
                    <div className="infoBox">
                      <div className="flexItem">
                        <span className="label">Sent on</span>
                      </div>
                      <div className="flexItem">
                        <span className="info">{new Date(feedback.createdAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                    <div className="infoBox">
                      <div className="flexItem">
                        <span className="label">Comments</span>
                      </div>
                      <div className="flexItem">
                        <span className="info">{feedback.feedback}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                {feedback.read === false && (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div className="actionBtnWrap">
                      <Button variant="link" className="tealBtn" onClick={markAsViewed}>
                        Mark as viewed
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
      </Layout>{" "}
      <ToastContainer />
    </>
  );
};

export default DetailedFeatureRequest;
