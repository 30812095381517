import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { baseUrl } from "./AppUrls";

class ApiClient {
  constructor() {
    if (!ApiClient.instance) {
      this.baseUrl = baseUrl;
      ApiClient.instance = this;
    }

    return ApiClient.instance;
  }

  async request(endpoint, method = "GET", body = null, isFormData = false, headers = {}, login = false) {
    try {
      const token = Cookies.get("token");
      headers["Authorization"] = `Bearer ${token}`;

      const config = {
        method,
        headers: {
          ...headers,
        },
      };

      if (isFormData) {
        config.body = body;
      } else {
        config.headers["Content-Type"] = "application/json";
        if (body) {
          config.body = JSON.stringify(body);
        }
      }

      const response = await fetch(`${this.baseUrl}${endpoint}`, config);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        if (response.status === 401 && !login) {
          Cookies.remove("token");
          window.location.href = "/login";
        } else {
          const errorData = await response.json();
          if (errorData.message) {
            toast.error(errorData.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred");
      }
    }
  }
}

const apiClient = new ApiClient();
Object.freeze(apiClient);

export default apiClient;
