import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const UserManagementTable = ({ users }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const columns = [
    {
      name: "User",
      sortable: true,
      width: "300px",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="userInfoWrapper">
          <div className="flexWrap">
            <div className="flexItemOne">
              <img alt="portrait" src={row.portrait} className="portrait" />
            </div>
            <div className="flexItemTwo">
              <p className="name">{row.name}</p>
              <p className="email">{row.email}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Verification Status",
      sortable: true,
      selector: (row) => row.verificationStatus,
      cell: ({ verificationStatus }) => (
        <div className="verificationStatusWrapper">
          {verificationStatus.toLowerCase() === "pending" ? (
            <div className="verificationStatusLabel pending">{verificationStatus}</div>
          ) : (
            ""
          )}
          {verificationStatus.toLowerCase() === "verified" ? (
            <div className="verificationStatusLabel verified">{verificationStatus}</div>
          ) : (
            ""
          )}
          {verificationStatus.toLowerCase() === "rejected" ? (
            <div className="verificationStatusLabel rejected">{verificationStatus}</div>
          ) : (
            ""
          )}
          {verificationStatus.toLowerCase() === "suspended" ? (
            <div className="verificationStatusLabel rejected">{verificationStatus}</div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "Gender",
      sortable: true,
      selector: (row) => row.gender,
      cell: (row) => (
        <div>
          <div className="genderLabel">{row.gender}</div>
        </div>
      ),
    },
    {
      name: "Location",
      sortable: true,
      selector: (row) => row.location,
      cell: (row) => (
        <div>
          <div className="locationLabel">{row.location}</div>
        </div>
      ),
    },
    {
      name: "Account Status",
      sortable: true,
      selector: (row) => row.accountStatus,
      cell: ({ accountStatus }) => (
        <div className="accountStatusWrapper">
          {accountStatus.toLowerCase() === "pending" && (
            <div className="accountStatusLabel pending">{capitalizeFirstLetter(accountStatus)}</div>
          )}
          {accountStatus.toLowerCase() === "accepted" && (
            <div className="accountStatusLabel accepted">{capitalizeFirstLetter(accountStatus)}</div>
          )}
          {accountStatus.toLowerCase() === "rejected" && (
            <div className="accountStatusLabel rejected">{capitalizeFirstLetter(accountStatus)}</div>
          )}
          {accountStatus.toLowerCase() === "suspended" && (
            <div className="accountStatusLabel suspended">{capitalizeFirstLetter(accountStatus)}</div>
          )}
        </div>
      ),
    },
    {
      name: "Onboarded",
      sortable: true,
      selector: (row) => row.onboarded,
      cell: (row) => (
        <div>
          <div className="onboardedLabel">{row.onboarded}</div>
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <div className="actionButtonWrapper">
            <Link to={`/user-management/users/${row.name}`} variant="link" className="editBtn" state={{ user: row }}>
              Edit
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const data = users.map((user) => {
    const onboardedDate = new Date(user.createdAt);
    const formattedDate = `${onboardedDate.getDate().toString().padStart(2, "0")}-${(onboardedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${onboardedDate.getFullYear()}`;
    return {
      ...user,
      name: user.name,
      email: user.email,
      portrait: user.profileImage,
      verificationStatus: user.isVerifiedAccount ? "Verified" : "Pending",
      gender: user.gender,
      location: user.locationName,
      accountStatus: user.accountStatus || "pending",
      onboarded: formattedDate,
    };
  });

  return <DataTable columns={columns} data={data} />;
};

export default UserManagementTable;
