import React, { useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Heading, Layout } from "../../../components";
import Images from "../../../assets/img";

const ViewChats = () => {
  const [deleteMessageModalShow, setDeleteMessageModalShow] = useState(false);

  const handleDeleteMessageClose = () => setDeleteMessageModalShow(false);
  const handleDeleteMessageOpen = () => setDeleteMessageModalShow(true);

  return (
    <Layout>
      <div className="viewChatPageContainer">
        <Heading title="Chats" />
        <div className="cardReportWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <p className="title">Chats with</p>
                    </div>
                    <div className="flexItemTwo">
                      <p className="number">45 members</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="headingWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h1 className="heading">Chats</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportedCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">User</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="greyBtn">
                        View profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="reportedCardInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p className="heading">Chats With</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Alexa Liras</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">XCF56955</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        {/* <div className="accountStatusLabel offline">Offline</div> */}
                        <div className="accountStatusLabel online">Online</div>
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <Button variant="link" className="greyBtn">
                        View profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="chatInfoWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="cardWrap">
                  <div className="searchInputWrapper">
                    <div className="searchWrap">
                      <div className="iconWrap">
                        <Images.SearchIcon />
                      </div>
                      <div className="inputWrap">
                        <input type="text" placeholder="Search messages" className="inputControl" />
                      </div>
                    </div>
                  </div>
                  <div className="chatBox chatLeftBox">
                    <div className="flexWrap">
                      <div className="flexItem textBox">
                        <span className="text">
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo officia unde dolorem
                          ratione at.
                        </span>
                      </div>
                      <div className="flexItem">
                        <Button variant="link" className="deleteBtn" onClick={() => handleDeleteMessageOpen()}>
                          Delete
                        </Button>
                      </div>
                    </div>
                    <span className="time">6:08 PM</span>
                  </div>
                  <div className="chatBox chatRightBox">
                    <div className="flexWrap">
                      <div className="flexItem textBox">
                        <span className="text">
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores, laudantium
                        </span>
                      </div>
                      <div className="flexItem">
                        <Button variant="link" className="deleteBtn" onClick={() => handleDeleteMessageOpen()}>
                          Delete
                        </Button>
                      </div>
                    </div>
                    <span className="time">6:08 PM</span>
                  </div>
                  <div className="chatBox chatRightBox">
                    <div className="flexWrap">
                      <div className="flexItem textBox">
                        <span className="text">
                          Incidunt adipisci beatae sunt possimus id non quas accusantium ex commodi, fugit consequuntur
                          optio unde exercitationem.
                        </span>
                      </div>
                      <div className="flexItem">
                        <Button variant="link" className="deleteBtn" onClick={() => handleDeleteMessageOpen()}>
                          Delete
                        </Button>
                      </div>
                    </div>
                    <span className="time">6:08 PM</span>
                  </div>
                  <div className="chatBox chatLeftBox">
                    <div className="flexWrap">
                      <div className="flexItem textBox">
                        <span className="text">
                          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium ex at aut iste quos
                          optio libero tempora eaque? Minus ratione deserunt suscipit minima, debitis blanditiis fugiat
                          possimus facilis eum? Minima!
                        </span>
                      </div>
                      <div className="flexItem">
                        <Button variant="link" className="deleteBtn" onClick={() => handleDeleteMessageOpen()}>
                          Delete
                        </Button>
                      </div>
                    </div>
                    <span className="time">6:08 PM</span>
                  </div>
                  <div className="chatBox chatRightBox">
                    <div className="flexWrap">
                      <div className="flexItem textBox">
                        <span className="text">Incidunt adipisci beatae sunt.</span>
                      </div>
                      <div className="flexItem">
                        <Button variant="link" className="deleteBtn" onClick={() => handleDeleteMessageOpen()}>
                          Delete
                        </Button>
                      </div>
                    </div>
                    <span className="time">6:08 PM</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal show={deleteMessageModalShow} onHide={handleDeleteMessageClose} dialogClassName="modal570w">
          <Modal.Body>
            <div className="cmnModalWrapper rejectSelfieModalWrapper">
              <div className="contentWrap">
                <h6 className="heading">Delete message?</h6>
                <p className="info">Are you sure you want to delete this message?</p>
              </div>
              <div className="actionBtnWrap">
                <Button className="actionBtn redBtn" variant="link" onClick={handleDeleteMessageClose}>
                  Delete
                </Button>
                <Button className="actionBtn whiteBtn" variant="link" onClick={handleDeleteMessageClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default ViewChats;
