import React from "react";
import { Breadcrumbs } from "../../components";
import { Container, Row, Col, Form } from "react-bootstrap";
import Images from "../../assets/img";
import "./Header.scss";

const Header = () => {
  return (
    <div className="headerComponentContainer">
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={8}>
            <Breadcrumbs />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={4}>
            <Form>
              <div className="searchInputWrapper">
                <div className="searchWrap">
                  <div className="iconWrap">
                    <Images.SearchIcon />
                  </div>
                  <div className="inputWrap">
                    <input type="text" placeholder="Type here..." className="inputControl" />
                  </div>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
