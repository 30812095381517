import Cookies from "js-cookie";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/img";
import constants from "../../utils/constants";
import "./Sidebar.scss";

const Path = constants.routePath;

const Sidebar = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const [logoutConfirmationModalShow, setLogoutConfirmationModalShow] = useState(false);

  const handleLogout = () => {
    Cookies.remove("token");
    navigate(`/${Path.SIGNIN}`);
  };

  const handleLogoutOpen = () => setLogoutConfirmationModalShow(true);
  const handleLogoutClose = () => setLogoutConfirmationModalShow(false);

  return (
    <div className="sidebarComponentContainer">
      <div className="userInfoWrapper">
        <div className="flexWrap">
          <div className="flexItem">
            <span className="circularNameInitials">A</span>
          </div>
          <div className="flexItem">
            <p className="name">Admin</p>
            <p className="email">admin@muslym.app</p>
          </div>
        </div>
      </div>
      <div className="navLinkWrapper">
        <Link to="/" className={`${pathname === "/" ? "active" : ""} navLinkItem`}>
          <div className="flexWrap">
            <div className="iconWrap">
              <Images.HomeIcon />
            </div>
            <span className="title">Dashboard</span>
          </div>
        </Link>
        <Link
          to={`/${Path.USER_MANAGEMENT}/${Path.USERS}`}
          className={`${pathname.includes("user-management") ? "active" : ""} navLinkItem`}>
          <div className="flexWrap">
            <div className="iconWrap">
              <Images.UserIcon />
            </div>
            <span className="title">User management</span>
          </div>
        </Link>
        <Link to={`/${Path.REPORTS}`} className={`${pathname.includes("reports") ? "active" : ""} navLinkItem`}>
          <div className="flexWrap">
            <div className="iconWrap">
              <Images.DangerIcon />
            </div>
            <span className="title">Reports</span>
          </div>
        </Link>
        <Link
          to={`/${Path.FEEDBACKS_AND_REQUESTS}`}
          className={`${pathname.includes("feedbacks") ? "active" : ""} navLinkItem`}>
          <div className="flexWrap">
            <div className="iconWrap">
              <Images.FeedbackIcon />
            </div>
            <span className="title">Feedbacks & Requests</span>
          </div>
        </Link>
        <Link to={`/${Path.CHATS}`} className={`${pathname.includes("chats") ? "active" : ""} navLinkItem`}>
          <div className="flexWrap">
            <div className="iconWrap">
              <Images.ChatIcon />
            </div>
            <span className="title">Chats</span>
          </div>
        </Link>
      </div>
      <div className="navLinkWrapper">
        <h3 className="heading">ADMIN SETTINGS</h3>
        <Link to={`/${Path.PROFILE}`} className={`${pathname === "/profile" ? "active" : ""} navLinkItem`}>
          <div className="flexWrap">
            <div className="iconWrap">
              <Images.ProfileIcon />
            </div>
            <span className="title">Profile</span>
          </div>
        </Link>
        <div className="navLinkItem" onClick={handleLogoutOpen} style={{ cursor: "pointer" }}>
          <div className="flexWrap">
            <div className="iconWrap logoutIcon">
              <Images.LogoutIcon />
            </div>
            <span className="title">Log out</span>
          </div>
        </div>
      </div>
      <Modal show={logoutConfirmationModalShow} onHide={handleLogoutClose} dialogClassName="modal570w">
        <Modal.Body>
          <div className="cmnModalWrapper rejectSelfieModalWrapper">
            <div className="contentWrap">
              <h6 className="heading">Logout</h6>
              <p className="info">Are you sure you want to logout?</p>
            </div>
            <div className="actionBtnWrap">
              <Button className="actionBtn tealBtn" variant="link" onClick={handleLogout}>
                Logout
              </Button>
              <Button className="actionBtn whiteBtn" variant="link" onClick={handleLogoutClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sidebar;
