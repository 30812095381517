import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import apiClient from "../../api/ApiClients";
import { Heading, Layout, TotalReportsReceived } from "../../components";
import constants from "../../utils/constants";
import "./Reports.scss";

const Path = constants.routePath;

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState({ totalReports: 0, totalUnresolved: 0, totalResolved: 0 });

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      const response = await apiClient.request("userInteractions/get-total-reports", "GET");
      if (response && response.error === false) {
        setReports(response);
      }
      setLoading(false);
    };

    fetchReports();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Layout>
      <div className="reportsPageContainer">
        <Heading title="Reports" />
        <TotalReportsReceived number={reports.totalReports} />
        <div className="reportCatergoryWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={3}>
                <Link to={`/${Path.REPORTS}/${Path.PENDING_REPORTS}`}>
                  <div className="cardWrap pending">
                    <div className="cardLinkWrap ">
                      <h1 className="heading">Pending reports</h1>
                      <p className="number">{reports.totalUnresolved}</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={3}>
                <Link to={`/${Path.REPORTS}/${Path.RESOLVED_REPORTS}`}>
                  <div className="cardWrap resolved">
                    <div className="cardLinkWrap ">
                      <h1 className="heading">Resolved reports</h1>
                      <p className="number">{reports.totalResolved}</p>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
