import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Heading, Layout } from "../../components";
import "./Chats.scss";
import constants from "../../utils/constants";
import Images from "../../assets/img";

const Path = constants.routePath;

const Chats = () => {
  const navigate = useNavigate();

  const handleViewUserChats = (data) => {
    const userId = "1";
    navigate(`/${Path.CHATS}/${userId}`);
  };

  return (
    <Layout>
      <div className="chatsPageContainer">
        <Heading title="Chats" />
        <div className="chatListWrapper">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        {/* <div className="accountStatusLabel offline">Offline</div> */}
                        <div className="accountStatusLabel online">Online</div>
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <div className="actionButtonWrapper">
                        <Button variant="link" className="viewBtn" onClick={() => handleViewUserChats()}>
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <div className="actionButtonWrapper">
                        <Button variant="link" className="viewBtn" onClick={() => handleViewUserChats()}>
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        {/* <div className="accountStatusLabel offline">Offline</div> */}
                        <div className="accountStatusLabel online">Online</div>
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <div className="actionButtonWrapper">
                        <Button variant="link" className="viewBtn" onClick={() => handleViewUserChats()}>
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <div className="actionButtonWrapper">
                        <Button variant="link" className="viewBtn" onClick={() => handleViewUserChats()}>
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <div className="actionButtonWrapper">
                        <Button variant="link" className="viewBtn" onClick={() => handleViewUserChats()}>
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
                <div className="cardWrap">
                  <div className="flexWrap">
                    <div className="flexItemOne">
                      <img alt="portrait" src={Images.UserPortrait} className="portrait" />
                      <div className="infoBox">
                        <p className="name">Ahmed Hussain</p>
                        <p className="userId">
                          <span>User ID:</span>
                          <span className="id">ABC12345</span>
                        </p>
                      </div>
                      <div className="statusWrapper">
                        <div className="accountStatusLabel offline">Offline</div>
                        {/* <div className="accountStatusLabel online">Online</div> */}
                      </div>
                    </div>
                    <div className="flexItemTwo">
                      <div className="actionButtonWrapper">
                        <Button variant="link" className="viewBtn" onClick={() => handleViewUserChats()}>
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Chats;
